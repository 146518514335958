import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import normalize from 'normalize-object'

import { GeneralApiProblem, getGeneralApiProblem } from '@/services/api/apiProblem'

import { AppLocale, LocationInfo, User, UserSignup } from '@/types'

dayjs.extend(customParseFormat)

type ApiConfig = {
  baseurl: string
  timeout: number
  locale: AppLocale
  locationSlug: string
  locationId: string

  authToken?: string | null
  fid?: string
}

const __DEV__ = process.env.NODE_ENV === 'development'
const BASE_URL = process.env.NEXT_PUBLIC_API_BASE_URL as string

export const DEFAULT_API_CONFIG: ApiConfig = {
  baseurl: BASE_URL,
  timeout: 10000,
  locale: 'es',
  locationSlug: '',
  locationId: '8',
}

class Api {
  config: ApiConfig

  constructor(config: ApiConfig = DEFAULT_API_CONFIG) {
    this.config = { ...config }
  }

  setLocationSlug(slug: string) {
    this.config.locationSlug = slug
  }

  setLocationId(locationId: string | number) {
    this.config.locationId = `${locationId}`
  }

  setAuthToken(token: string | null) {
    this.config.authToken = token
  }

  setLocale(locale: AppLocale) {
    this.config.locale = locale
  }

  setFID(fid: string | null) {
    if (fid) {
      this.config.fid = fid
    }
  }

  getHeaders(isJson = true) {
    const headers: Record<string, string> = {}

    headers['Accept'] = 'application/json'
    headers['X-localization'] = this.config.locale
    headers['X-location-id'] = this.config.locationId

    headers['X-location-slug'] = this.config.locationSlug
    headers['X-app-version'] = '1.8.1'
    headers['X-app-platform'] = 'web'
    headers['vermut-tz'] = Intl.DateTimeFormat().resolvedOptions().timeZone
    headers['X-firebase-installation-id'] = this.config.fid || ''

    if (isJson) {
      headers['Content-Type'] = 'application/json'
    }

    return headers
  }

  getCommonQueryParams(): URLSearchParams {
    const params = new URLSearchParams()

    params.set('locationId', this.config.locationId)
    params.set('localization', this.config.locale)
    // params.set('locationSlug', this.config.locationId)

    params.set('timezone', Intl.DateTimeFormat().resolvedOptions().timeZone)

    return params
  }

  async sendRequest<T>(
    url: string,
    options: {
      method?: 'GET' | 'POST' | 'PUT'
      body?: object
      cache?: 'force-cache' | 'no-store'
      revalidate?: number
      noheader?: boolean
    } = {},
  ): Promise<{ kind: 'ok'; data: T } | GeneralApiProblem> {
    const { method = 'GET', body, cache, revalidate } = options

    const headers = this.getHeaders()

    let includeAuth = false

    if (
      url.includes('/api/mobile/me') ||
      url.includes('/api/logout') ||
      url.includes('/api/mobile/bookings') ||
      url.includes('/api/mobile/misc/signup-done') ||
      url.includes('/api/mobile/misc/update-current-location') ||
      url.includes('/api/mobile/misc/get-intents') ||
      url.includes('/api/mobile/me/update-user-intent') ||
      url.includes('/api/mobile/misc/chatgpt') ||
      // url.includes('/api/customer/community/get-list') ||
      // url.includes('/api/customer/user/list-friendships') ||
      url.match(/\/api\/mobile\/events\/\d+\/dates/) ||
      url.match(/\/api\/mobile\/misc\/schedules\/\d+/)
    ) {
      includeAuth = true
    }

    if (includeAuth && this.config.authToken && this.config.authToken != 'null') {
      headers['Authorization'] = `Bearer ${this.config.authToken}`
    }

    const reqOptions: RequestInit = {
      method,
      headers: headers,
    }

    if (cache && revalidate === undefined) {
      reqOptions.cache = cache
      // reqOptions.next = undefined
    }

    if (revalidate) {
      reqOptions.next = { revalidate }
    }

    if (options.noheader) {
      reqOptions.headers = { Accept: 'application/json' }
    }

    if (body) {
      reqOptions.body = JSON.stringify(normalize(body, 'snake'))
    }

    let response
    try {
      response = await fetch(url, reqOptions)
    } catch (e) {
      // debugger
    }

    // console.log(`response status ====== `, response.status, response.statusText)

    if (!response?.ok) {
      const problem = await getGeneralApiProblem(response, url)
      if (problem) return problem
    }

    try {
      const rawData = await response.json()

      return { kind: 'ok', data: normalize(rawData, 'camel') }
    } catch (err) {
      if (__DEV__) {
        if (err instanceof Error) {
          console.log(`Bad data: ${err.message}\n${response}`, err.stack)
        } else {
          console.log(`Bad data: ${err}`)
        }
      }

      const reason = err instanceof Error ? err.message : typeof err === 'string' ? err : '' + err

      return { kind: 'bad-data', error: reason }
    }
  }

  async getCategories<T>(): Promise<{ kind: 'ok'; data: T } | GeneralApiProblem> {
    const params = this.getCommonQueryParams()

    const url = this.config.baseurl + `/api/mobile/event-formats?${params.toString()}`

    return this.sendRequest<T>(url, { revalidate: 300 })
  }

  async searchActivites<T>(
    page = '1',
    category?: string | undefined,
    searchq?: string | undefined,
  ): Promise<{ kind: 'ok'; data: T } | GeneralApiProblem> {
    const params = this.getCommonQueryParams()
    params.set('page', page)

    if (category && category !== '1') {
      params.set('formatType', category)
    }

    if (searchq) {
      params.set('searchq', searchq)
    }

    const url = this.config.baseurl + `/api/mobile/events?${params.toString()}`

    return this.sendRequest<T>(url, { revalidate: 300 })
  }

  async getFeaturedActivites<T>(): Promise<{ kind: 'ok'; data: T } | GeneralApiProblem> {
    const params = this.getCommonQueryParams()
    // params.set('featured', 'true')
    // params.set('online', 'false')

    const url = this.config.baseurl + `/api/customer/activity/home?${params.toString()}`

    console.log(`getFeaturedActivites() === url ==== ${url}`)

    return this.sendRequest<T>(url, { cache: 'no-store' })
  }

  async getOnlineActivites<T>(): Promise<{ kind: 'ok'; data: T } | GeneralApiProblem> {
    const params = this.getCommonQueryParams()
    params.set('featured', 'true')
    params.set('online', 'true')

    const url = this.config.baseurl + `/api/mobile/events?${params.toString()}`

    return this.sendRequest<T>(url, { revalidate: 300 })
  }

  async getActivities<T>(
    page = '1',
    activityType: 'activity' | 'gathering' = 'activity',
    categories?: string | undefined,
    searchq?: string | undefined,
    inPerson?: undefined | boolean,
    isOnline?: undefined | boolean,
    isTrip?: undefined | boolean,
  ): Promise<{ kind: 'ok'; data: T } | GeneralApiProblem> {
    const params = this.getCommonQueryParams()
    params.set('page', page)
    params.set('per_page', '8')

    if (activityType === 'gathering') {
      params.set('activity_type', 'gathering')
    }

    if (searchq) {
      params.set('searchq', searchq)
    }

    if (categories && categories !== '1') {
      params.set('categories', categories)
    }

    if (isOnline) {
      params.set('is_online', isOnline.toString())
    }

    if (inPerson) {
      params.set('in_person', inPerson.toString())
    }

    if (isTrip) {
      params.set('is_trip', isTrip.toString())
    }

    const url = this.config.baseurl + `/api/customer/activities?${params.toString()}`
    console.log(`\n\n\n ${url} \n\n\n`)
    return this.sendRequest<T>(url, { revalidate: 300 })
  }

  async searchHangouts<T>(
    page = '1',
    category?: string | undefined,
    searchq?: string | undefined,
  ): Promise<{ kind: 'ok'; data: T } | GeneralApiProblem> {
    const params = this.getCommonQueryParams()
    params.set('page', page)
    params.set('per_page', '8')
    params.set('type', 'gathering')

    if (category && category !== '1') {
      params.set('formatType', category)
    }

    if (searchq) {
      params.set('searchq', searchq)
    }

    const url = this.config.baseurl + `/api/mobile/events?${params.toString()}`

    return this.sendRequest<T>(url, { revalidate: 300 })
  }

  async getLatestHangouts<T>(): Promise<{ kind: 'ok'; data: T } | GeneralApiProblem> {
    const params = this.getCommonQueryParams()
    params.set('type', 'gathering')
    params.set('latest_hangouts', 'true')

    const url = this.config.baseurl + `/api/mobile/events?${params.toString()}`

    // console.log(`getFeaturedActivites() === url ==== ${`/api/mobile/events?${params.toString()}`}`)

    return this.sendRequest<T>(url, { cache: 'no-store', revalidate: 0 })
  }

  async getActivityDetails<T>(activityId: string | number): Promise<{ kind: 'ok'; data: T } | GeneralApiProblem> {
    const params = this.getCommonQueryParams()
    // params.set('type', 'gathering')

    const url = this.config.baseurl + `/api/mobile/events/${activityId}?${params.toString()}`

    return this.sendRequest<T>(url, { revalidate: 300 })
  }

  async getActivityDates<T>(activityId: string | number): Promise<{ kind: 'ok'; data: T } | GeneralApiProblem> {
    const url = this.config.baseurl + `/api/mobile/events/${activityId}/dates?per_page=9`

    return this.sendRequest<T>(url, { cache: 'no-store' })
  }

  async getScheduleIdDetails<T>(scheduleId: string | number): Promise<{ kind: 'ok'; data: T } | GeneralApiProblem> {
    const url = this.config.baseurl + `/api/mobile/misc/schedules/${scheduleId}`

    return this.sendRequest<T>(url, { cache: 'no-store' })
  }

  async getActivityReviews<T>(activityId: string | number): Promise<{ kind: 'ok'; data: T } | GeneralApiProblem> {
    const params = this.getCommonQueryParams()

    const url = this.config.baseurl + `/api/mobile/events/${activityId}/reviews?${params.toString()}`

    return this.sendRequest<T>(url, { revalidate: 300 })
  }

  async subscribeNewsletter<T>(email: string): Promise<{ kind: 'ok'; data: T } | GeneralApiProblem> {
    const url = this.config.baseurl + `/api/mobile/misc/newsletters`

    return this.sendRequest<T>(url, { method: 'POST', body: { email } })
  }

  async otp<T>(phone: string): Promise<{ kind: 'ok'; data: T } | GeneralApiProblem> {
    const url = this.config.baseurl + `/api/mobile/otp`

    return this.sendRequest<T>(url, { method: 'POST', body: { phone } })
  }

  async otpVerfiy<T>(phone: string, otp: string): Promise<{ kind: 'ok'; data: T } | GeneralApiProblem> {
    const url = this.config.baseurl + `/api/mobile/otp-verify`

    return this.sendRequest<T>(url, { method: 'POST', body: { phone, otp } })
  }

  async getCountryStates<T>(countryCode: string): Promise<{ kind: 'ok'; data: T } | GeneralApiProblem> {
    const params = this.getCommonQueryParams()
    params.set('countryCode', countryCode)

    const url = this.config.baseurl + `/api/mobile/misc/states?${params.toString()}`

    return this.sendRequest<T>(url, { revalidate: 300 })
  }

  async getLocationsByCountry<T>(countryId: string): Promise<{ kind: 'ok'; data: T } | GeneralApiProblem> {
    const params = this.getCommonQueryParams()
    params.set('country_id', countryId)

    const url = this.config.baseurl + `/api/mobile/locations?${params.toString()}`

    return this.sendRequest<T>(url, { revalidate: 300 })
  }

  async signUp<T>(formData: UserSignup): Promise<{ kind: 'ok'; data: T } | GeneralApiProblem> {
    const url = this.config.baseurl + `/api/mobile/signup`

    return this.sendRequest<T>(url, {
      method: 'POST',
      body: formData,
    })
  }

  async signupDone<T>(): Promise<{ kind: 'ok'; data: T } | GeneralApiProblem> {
    const url = this.config.baseurl + `/api/mobile/misc/signup-done`

    return this.sendRequest<T>(url, { method: 'POST', body: {} })
  }

  async updateCurrentLocation<T>(currentLocationId: number): Promise<{ kind: 'ok'; data: T } | GeneralApiProblem> {
    const url = this.config.baseurl + `/api/mobile/misc/update-current-location`

    return this.sendRequest<T>(url, { method: 'PUT', body: { currentLocationId } })
  }

  async getInterestIntents<T>(): Promise<{ kind: 'ok'; data: T } | GeneralApiProblem> {
    const params = this.getCommonQueryParams()

    const url = this.config.baseurl + `/api/mobile/misc/get-intents?${params.toString()}`

    return this.sendRequest<T>(url, { revalidate: 300 })
  }

  async updateUserIntent<T>(intentId: number): Promise<{ kind: 'ok'; data: T } | GeneralApiProblem> {
    const url = this.config.baseurl + `/api/mobile/me/update-user-intent`

    return this.sendRequest<T>(url, { method: 'PUT', body: { intents: [intentId] } })
  }

  async updateUserIntersets<T>(interests: number[]): Promise<{ kind: 'ok'; data: T } | GeneralApiProblem> {
    const url = this.config.baseurl + `/api/mobile/me/format-types`

    return this.sendRequest<T>(url, { method: 'PUT', body: { formatTypes: interests } })
  }

  async loginWithAccessToken<T>(accessToken: string): Promise<{ kind: 'ok'; data: T } | GeneralApiProblem> {
    const url = window.location.origin + `/api/login`

    return this.sendRequest<T>(url, {
      method: 'POST',
      body: { accessToken },
    })
  }

  async logout<T>(): Promise<{ kind: 'ok'; data: T } | GeneralApiProblem> {
    const url = window.location.origin + `/api/logout`

    return this.sendRequest<T>(url, {
      method: 'POST',
      body: {},
    })
  }

  async me<T>(): Promise<{ kind: 'ok'; data: T } | GeneralApiProblem> {
    const url = this.config.baseurl + `/api/mobile/me`

    return this.sendRequest<T>(url)
  }

  async userProfile<T>(): Promise<{ kind: 'ok'; data: T } | GeneralApiProblem> {
    const url = this.config.baseurl + `/api/mobile/me/profile`

    return this.sendRequest<T>(url)
  }

  async otherUserProfile(userId: string | number): Promise<{ kind: 'ok'; data: User } | GeneralApiProblem> {
    const url = this.config.baseurl + `/api/mobile/user-profile/${userId}`

    return this.sendRequest<User>(url)
  }

  async startTrial<T>(): Promise<{ kind: 'ok'; data: T } | GeneralApiProblem> {
    const url = this.config.baseurl + `/api/mobile/stripe/trial-start`

    return this.sendRequest<T>(url, {
      method: 'POST',
      // body: {},
    })
  }

  async creditBooking<T>(bookingInfo): Promise<{ kind: 'ok'; data: T } | GeneralApiProblem> {
    const url = this.config.baseurl + `/api/mobile/bookings/book-with-credit`

    return this.sendRequest<T>(url, {
      method: 'POST',
      body: bookingInfo,
    })
  }

  async cashBooking<T>(bookingInfo): Promise<{ kind: 'ok'; data: T } | GeneralApiProblem> {
    const url = this.config.baseurl + `/api/mobile/bookings/book-with-cash`

    return this.sendRequest<T>(url, {
      method: 'POST',
      body: bookingInfo,
    })
  }

  async sendToChatGpt<T>(userInputText: string): Promise<{ kind: 'ok'; data: T } | GeneralApiProblem> {
    const url = this.config.baseurl + `/api/mobile/misc/chatgpt`

    return this.sendRequest<T>(url, {
      method: 'POST',
      body: { userInputText },
    })
  }

  async getUserPopupNotifications<T>(): Promise<{ kind: 'ok'; data: T } | GeneralApiProblem> {
    const params = this.getCommonQueryParams()

    const url = this.config.baseurl + `/api/mobile/me/popup-notifications?${params.toString()}`

    return this.sendRequest<T>(url, { cache: 'no-store' })
  }

  async notificationMarkAsRead<T>(
    notificationId: number | string,
  ): Promise<{ kind: 'ok'; data: T } | GeneralApiProblem> {
    const url = this.config.baseurl + `/api/mobile/me/notification-mark-as-read`

    return this.sendRequest<T>(url, {
      method: 'POST',
      body: { id: notificationId },
    })
  }

  async getForumList<T>(page = '1'): Promise<{ kind: 'ok'; data: T } | GeneralApiProblem> {
    const params = this.getCommonQueryParams()
    params.set('page', page)

    const url = this.config.baseurl + `/api/customer/misc/list-category-channels?${params.toString()}`

    return this.sendRequest<T>(url, { revalidate: 300 })
  }

  async getCollaboratorForumList<T>(page = '1'): Promise<{ kind: 'ok'; data: T } | GeneralApiProblem> {
    const params = this.getCommonQueryParams()
    params.set('page', page)

    const url = this.config.baseurl + `/api/customer/misc/list-collaborator-channels?${params.toString()}`

    return this.sendRequest<T>(url, { revalidate: 300 })
  }

  async searchForumList<T>(
    page = '1',
    perPage = '8',
    searchq = '',
  ): Promise<{ kind: 'ok'; data: T } | GeneralApiProblem> {
    const params = this.getCommonQueryParams()

    params.set('page', page)
    params.set('per_page', perPage)
    if (searchq) {
      params.set('searchq', searchq)
    }

    const url = this.config.baseurl + `/api/customer/misc/list-chat-channels?${params.toString()}`

    return this.sendRequest<T>(url, { revalidate: 300 })
  }

  async getForumDetails<T>(channelId: string): Promise<{ kind: 'ok'; data: T } | GeneralApiProblem> {
    const params = this.getCommonQueryParams()
    params.set('channel_id', channelId)

    const url = this.config.baseurl + `/api/customer/misc/show-chat-channel?${params.toString()}`

    return this.sendRequest<T>(url, { revalidate: 300 })
  }

  async getLatestPosts<T>(page = 1, perPage = 10, userId = null): Promise<{ kind: 'ok'; data: T } | GeneralApiProblem> {
    const params = this.getCommonQueryParams()

    params.set('page', page.toString())
    params.set('perPage', perPage.toString())

    if (userId) {
      params.set('user_id', userId)
    }

    const url = this.config.baseurl + `/api/customer/post/get-list?${params.toString()}`

    return this.sendRequest<T>(url, { revalidate: 300 })
  }

  async getPostDetails<T>(postId: string | number): Promise<{ kind: 'ok'; data: T } | GeneralApiProblem> {
    const params = this.getCommonQueryParams()
    params.set('id', postId as string)

    const url = this.config.baseurl + `/api/customer/post/get-one?${params.toString()}`

    return this.sendRequest<T>(url, { revalidate: 300 })
  }

  async getLocationInfo<T>(formData: any): Promise<{ kind: 'ok'; data: T } | GeneralApiProblem> {
    const url = this.config.baseurl + `/api/customer/location/upsert`

    return this.sendRequest<T>(url, {
      method: 'POST',
      body: formData,
    })
  }

  async getFeaturedLocations<T>(): Promise<{ kind: 'ok'; data: T } | GeneralApiProblem> {
    const params = this.getCommonQueryParams()
    // params.set('type', 'gathering')

    const url = this.config.baseurl + `/api/customer/location/featured?${params.toString()}`

    return this.sendRequest<T>(url, { revalidate: 600 })
  }

  async updateLocationCookie(newLocation: LocationInfo) {
    try {
      const cookieUrl = window.location.origin + `/api/location`
      await fetch(cookieUrl, {
        method: 'POST',
        body: JSON.stringify({ locationInfo: newLocation }),
      })
    } catch (e) {
      console.log(`updateLocationCookie failed`, e)
    }
  }

  async getPartnerReviews<T>(
    userId: string | number,
    page = 1,
    perPage = 10,
  ): Promise<{ kind: 'ok'; data: T } | GeneralApiProblem> {
    const params = this.getCommonQueryParams()
    // params.set('type', 'gathering')
    params.set('page', page.toString())
    params.set('perPage', perPage.toString())
    params.set('id', userId.toString())

    const url = this.config.baseurl + `/api/customer/user/list-reviews?${params.toString()}`

    return this.sendRequest<T>(url, { revalidate: 600 })
  }

  async getInterestGroups<T>(): Promise<{ kind: 'ok'; data: T } | GeneralApiProblem> {
    const params = this.getCommonQueryParams()

    params.set('per_page', '100')

    const url = this.config.baseurl + `/api/customer/category/get-list?${params.toString()}`

    return this.sendRequest<T>(url, { noheader: true })
  }

  async customerActivityGetList<T>({
    page,
    perPage,
    ctx,
    type,
    partnerId,
  }: {
    page: number
    perPage: number
    ctx?: 'upcoming' | 'history'
    type?: 'course' | 'class' | 'appointment' | 'gathering'
    partnerId?: number
  }): Promise<{ kind: 'ok'; data: T } | GeneralApiProblem> {
    const params = this.getCommonQueryParams()
    // params.set('type', 'gathering')
    params.set('page', page.toString())
    params.set('per_page', perPage.toString())
    if (ctx) {
      params.set('ctx', ctx)
    }
    if (type) {
      params.set('type', type)
    }
    if (partnerId) {
      params.set('partner_id', partnerId.toString())
    }

    const url = this.config.baseurl + `/api/customer/activity/get-list?${params.toString()}`

    return this.sendRequest<T>(url, { noheader: true })
  }

  async getCommunities<T>(page = 1, perPage = 10): Promise<{ kind: 'ok'; data: T } | GeneralApiProblem> {
    const params = this.getCommonQueryParams()
    params.set('page', page.toString())
    params.set('per_page', perPage.toString())

    const url = this.config.baseurl + `/api/customer/community/get-list?${params.toString()}`

    // console.log(`getFeaturedActivites() === url ==== ${`/api/mobile/events?${params.toString()}`}`)

    return this.sendRequest<T>(url, { cache: 'force-cache', revalidate: 30 })
  }

  async getListFriendships<T>(
    userId: number,
    page = 1,
    perPage = 10,
  ): Promise<{ kind: 'ok'; data: T } | GeneralApiProblem> {
    const params = this.getCommonQueryParams()
    params.set('page', page.toString())
    params.set('per_page', perPage.toString())
    params.set('id', userId.toString())
    const url = this.config.baseurl + `/api/customer/user/list-friendships?${params.toString()}`

    // console.log(`getFeaturedActivites() === url ==== ${`/api/mobile/events?${params.toString()}`}`)

    return this.sendRequest<T>(url, { cache: 'no-store', revalidate: 0 })
  }

  async customerPostGetList<T>(
    userId: number,
    page = 1,
    perPage = 10,
  ): Promise<{ kind: 'ok'; data: T } | GeneralApiProblem> {
    const params = this.getCommonQueryParams()
    params.set('page', page.toString())
    params.set('per_page', perPage.toString())
    params.set('user_id', userId.toString())
    const url = this.config.baseurl + `/api/customer/post/get-list?${params.toString()}`

    // console.log(`getFeaturedActivites() === url ==== ${`/api/mobile/events?${params.toString()}`}`)

    return this.sendRequest<T>(url, { cache: 'no-store', revalidate: 0 })
  }

  async customerChatchannelGetList<T>(
    userId: number,
    page = 1,
    perPage = 10,
  ): Promise<{ kind: 'ok'; data: T } | GeneralApiProblem> {
    const params = this.getCommonQueryParams()
    params.set('page', page.toString())
    params.set('per_page', perPage.toString())
    params.set('user_id', userId.toString())
    const url = this.config.baseurl + `/api/customer/chatchannel/get-list?${params.toString()}`

    // console.log(`getFeaturedActivites() === url ==== ${`/api/mobile/events?${params.toString()}`}`)

    return this.sendRequest<T>(url, { cache: 'no-store', revalidate: 0 })
  }
}

const api = new Api()

// Singleton instance of the API for convenience
export { api }
